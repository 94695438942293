import {
  campaigns
} from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      table_header: [],
      campaign_id: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
          label: "Title"
        },
        {
          key: "slug",
          label: "Slug"
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      activeTab: "all",
      currentPage: 1,
      form: {
        title: "",
        slug: "",
        camp_icon:"",
        is_active: 0
      },
      camp_icon: "",
      camp_img_url: "",
      edit: {
        camp_img_url: null,
      },
    };
  },
  computed: {
    
  },
  methods: {
    readFile(e, txt) {
      if (txt == "camp_icon") {
        this.camp_icon = e.target.files[0];
        if (this.camp_icon.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is More than 2MB",
          });
          this.$refs.camp_icon.reset();
          this.camp_img_url = "";
          this.edit.camp_img_url = "";
          this.camp_icon = "";
          return false;
        } else {
          this.camp_img_url = URL.createObjectURL(this.camp_icon);
          this.edit.camp_img_url = "";
          return true;
        }
      } 
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            campaigns.mrAppcampaignUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          url = campaigns.mrAppcampaignUrl + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            campaigns.mrAppcampaignUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          url = campaigns.mrAppcampaignUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            campaigns.mrAppcampaignUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          url = campaigns.mrAppcampaignUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async updateStatus(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = campaigns.mrAppcampaignupdateStatus + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async fetchCampaigns(id) {
      this.$store.commit("toast/updateStatus", true);
      try {
        let url = campaigns.mrAppcampaignUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.tile != "" && responseData.title != null) {
            this.form.title = responseData.title;
          }
          if (
            responseData.slug != "" &&
            responseData.slug != null
          ) {
            this.form.slug = responseData.slug;
          }
          if (responseData.is_active) {
            this.form.is_active = true;
          }
          if (responseData.camp_icon != "" && responseData.camp_icon != null) {
            this.edit.camp_img_url = responseData.camp_icon ? responseData.camp_icon : "";
          }
          this.campaign_id = responseData.id;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Fetching error!",
        });
      }
      this.$store.commit("toast/updateStatus", false);
    },
    async deleteCampaigns(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = campaigns.mrAppcampaignUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreCampaigns(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = campaigns.restoremrAppcampaign + "/" + id;
        const data = await this.postRequest(url, {});
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          // window.scrollTo({
          //    top: 0,
          //    behavior: "smooth",
          // });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = campaigns.mrAppcampaignUrl;
        if (this.$route.name == "edit-campaigns") {
          url = campaigns.mrAppcampaignUrl + "/" + this.campaign_id;
        }
        let dataAppend = new FormData();

        if (this.form.is_active) {
          dataAppend.append("is_active", 1);
        } else {
          dataAppend.append("is_active", 0);
        }

        if (this.camp_icon) {
          dataAppend.append("camp_icon", this.camp_icon);
        }

        for (var key in this.form) {
          if (
            key != "is_active" &&
            key != "camp_icon"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }

        if (this.$route.name == "edit-campaigns") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/field-rep-app/campaigns");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  //   tabActive() {
  //     if (this.activeTab == "trash") {
  //        if (this.table_header.includes("delete")) {
  //           let index = this.fields.findIndex(
  //              (item) => item.key == "delete"
  //           );
  //           this.fields[index] = {
  //              key: "restore",
  //           };
  //           let table_index = this.table_header.findIndex(
  //              (item) => item == "delete"
  //           );
  //           this.table_header[table_index] = "restore";
  //        } else {
  //           this.table_header.push("restore");
  //           this.fields.push({
  //              key: "restore",
  //           });
  //        }

  //        if (!this.can("restore-campaigns")) {
  //           let index = this.fields.findIndex(
  //              (item) => item.key == "restore"
  //           );
  //           if (index !== -1) {
  //              this.fields.splice(index, 1);
  //              let table_index = this.table_header.findIndex(
  //                 (item) => item == "restore"
  //              );
  //              this.table_header.splice(table_index, 1);
  //           }
  //        }

  //        let index = this.fields.findIndex((item) => item.key == "edit");
  //        if (index !== -1) {
  //           this.fields.splice(index, 1);
  //           let table_index = this.table_header.findIndex(
  //              (item) => item == "edit"
  //           );
  //           this.table_header.splice(table_index, 1);
  //        }
  //     } else {
  //        if (this.table_header.includes("restore")) {
  //           if (!this.can("edit-campaigns")) {
  //              this.fields[6] = {
  //                 key: "delete",
  //              };
  //           } else {
  //              this.fields[7] = {
  //                 key: "delete",
  //              };
  //           }

  //           let table_index = this.table_header.findIndex(
  //              (item) => item == "restore"
  //           );
  //           this.table_header[table_index] = "delete";
  //        }

  //        if (!this.can("edit-campaigns")) {
  //           let index = this.fields.findIndex((item) => item.key == "edit");
  //           if (index !== -1) {
  //              this.fields.splice(index, 1);
  //              let table_index = this.table_header.findIndex(
  //                 (item) => item == "edit"
  //              );
  //              this.table_header.splice(table_index, 1);
  //           }
  //        } else {
  //           if (!this.table_header.includes("edit")) {
  //              this.table_header.push("edit");
  //              this.fields[6] = {
  //                 key: "edit",
  //              };
  //           }
  //        }

  //        if (!this.can("delete-campaigns")) {
  //           let index = this.fields.findIndex(
  //              (item) => item.key == "delete"
  //           );
  //           if (index !== -1) {
  //              this.fields.splice(index, 1);
  //              let table_index = this.table_header.findIndex(
  //                 (item) => item == "delete"
  //              );
  //              this.table_header.splice(table_index, 1);
  //           }
  //        } else {
  //           if (!this.table_header.includes("delete")) {
  //              this.table_header.push("delete");
  //              this.fields[7] = {
  //                 key: "delete",
  //              };
  //           }
  //        }
  //     }
  //  },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    // activeTab(v) {
      // if (v) this.tabActive();
    // },
    "form.content_type"(v) {
      if (v == "pop_up") {
        this.form.ad_position = 1;
      }
    },
  },
  created() {
    if (
      this.$route.name == "add-campaigns" ||
      this.$route.name == "edit-campaigns"
    ) {
      if (this.$route.name == "edit-campaigns") {
        this.fetchCampaigns(this.$route.params.id);
      }
    } else {
      this.fetchData(this.activeTab);
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    // this.tabActive();
  },
};
